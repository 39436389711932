import ENV from '../services/Env.js';

export const addFile = (file, _type) => {
  let formData = new FormData();
  formData.append('type', _type);
  formData.append('file', file);

  return fetch(ENV.API_URL + '/files/add', {
    method: 'POST',

    body: formData
  }).then(function (response) {
    return response.json();
  });
}

export const addFileBase64 = (_file, _type) => {
 
  return fetch(ENV.API_URL + '/files/add64', {
    method: 'POST',
    body: JSON.stringify({
      file: _file,
      type: _type
    }) 
  }).then(function (response) {
    return response.json();
  });
}


export const storeData = (_key, _value) => {
  localStorage.setItem(_key, JSON.stringify(_value) );
}

export const retrieveData = (_key) => {
 let val = localStorage.getItem(_key);
 if(val != null && val != undefined) val = JSON.parse(val); 
 return val;
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const safelyParseJSON =  (json) => {
  var parsed = [];
  try {
    if(json) {
      //json = json.replace(/\"/g, "");
    } 
    parsed = JSON.parse(json)
  } catch (e) {
    // Oh well, but whatever...
    return parsed; 
  } 
  if(parsed == null || parsed == "") parsed = [];
  return parsed 
}

export const convertToTags =  (_list) => {
  let arr = [];
  if( Array.isArray(_list)){
    _list.map((item) => {
      arr.push({ tag: item });
    })
  } 
  return arr;
}
 
export const convertImages =  (_list, _path = null) => {
  let arr = _list ? _list.split(",") : [];
  if (_path)
    arr = arr.map((item) => {
      return (item = _path + item);
    }); 
  return arr;
}
