export default {
  SIZE: [
    { value: null, text: "" },
    { value: "s", text: "S" },
    { value: "m", text: "M" },
    { value: "l", text: "L" },
    { value: "xl", text: "XL" }, 
  ],
  OCCASION: [
    { value: null, text: "" },
    { value: 0, text: "Свидание" },
    { value: 1, text: "День рождения" },
    { value: 2, text: "Юбилей" },
    { value: 3, text: "Годовщина свадьбы" },
    { value: 4, text: "Выписка - Девочка" },
    { value: 5, text: "Выписка - Мальчик" },
    { value: 6, text: "Без повода" },
    { value: 7, text: "Цветочная подписка" },
  ],
  COLORS: [
    { value: 0, text: "Красный", color: "#e1422c" },
    { value: 3, text: "Розовый", color: "#ed5d91" },
    { value: 1, text: "Нежный", color: "#f5b9cc" },
    { value: 4, text: "Кремовый", color: "#fef0c7" },
    { value: 2, text: "Зеленый", color: "#43913f" },
    { value: 10, text: "Салатовый", color: "#a3d11d" },
    { value: 5, text: "Жёлтый", color: "#fae033" },
    { value: 6, text: "Оранжевый", color: "#f6ac32" },
    { value: 7, text: "Синий", color: "#5ac5df" },
    { value: 8, text: "Фиолетовый", color: "#a250c2" },
    { value: 9, text: "Белый", color: "#fff" },
    { value: 11, text: "Микс", color: null },

    { value: 11, text: "яркий", color: "#fcdce7" },
    { value: 11, text: "сиреневый", color: "#b3abd0" },
    { value: 11, text: "голубой", color: "#a2d5eb" }, 
  ],
  WHOM: [
    
    { value: 0, text: "Для мамы" },
    { value: 1, text: "Для любимой" },
    { value: 2, text: "Для девушки " },
    { value: 3, text: "Для мужчины " },
    { value: 4, text: "Для коллеги " },
    { value: 5, text: "Для друзей " },

    { value: 6, text: "Для учителя " },
    { value: 7, text: "Детские букеты" },
    { value: 8, text: "Корпоративное предложение " }, 
  ],
  FLOWERS: [
    { value: null, text: "" },
    { value: "Rozy", text: "Розы" },
    { value: "Roza kustovaya", text: "Роза кустовая" },
    { value: "Roza pionovvaluenaya", text: "Роза пионовидная" },
    { value: "Alstromeriya", text: "Альстромерия" },

    { value: "Anemony", text: "Анемоны" },

    { value: "Giacint", text: "Гиацинт" },
    { value: "Gerbery", text: "Герберы" },
    { value: "Gvozdiki", text: "Гвоздики" },
    { value: "Gortenziya", text: "Гортензия" },
    { value: "Georginy", text: "Георгины" },
    { value:  "Gipsofila", text: "Гипсофила" },
    { value: "Gladiolusy", text: "Гладиолусы" },
    { value: "Iris" , text: "Ирис" },
    { value: "Kalla", text: "Калла" },

    { value: "Lilii", text: "Лилии" },


    { value: "Mattiola" , text: "Маттиола" },
    { value: "Mimozy", text: "Мимозы" },
    { value: "Nartsiss", text: "Нарцисс" },
    { value: "Nutаn", text: "Нутан"},
    { value: "Piony", text: "Пионы" },
    { value:  "Podsolnuh", text: "Подсолнух" },
    { value: "Proteya", text: "Протея"},
    { value: "Romashki", text: "Ромашки" },
    { value: "Ranunkulus", text: "Ранункулюс" },
    { value: "Siren", text: "Сирень" },
    { value: "Strelitsiya", text: "Стрелиция"},
    { value: "Tyulpany", text: "Тюльпаны" },

    { value: "Hrizantemy", text: "Хризантемы" },
    { value: "Eustoma", text: "Эустома" },

    { value: "Amaralis", text: "Амариллис" },
    { value: "Leykodendron", text: "Леукодендрон" },
    { value: "Nutan", text: "Нутан" },
    { value: "Orhideya", text: "Орхидея" },

  ],
};
