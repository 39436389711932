export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    //Prod
    //HOST_URL: 'http://amour.devkot.ru/',
    //API_URL: 'http://amour.devkot.ru/server/public/api/v1',
    //PUBLIC_URL: 'http://amour.devkot.ru/server/public/uploads/',

    HOST_URL: 'https://amour-flora.ru/',
    API_URL: 'https://amour-flora.ru/server/public/api/v1',
    PUBLIC_URL: 'https://amour-flora.ru/server/public/uploads/',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };