import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
} from "../../redux/modules/storage";

import {
  getCategoryList as getCategoryListAction,
  update as updateAction,
} from "../../redux/modules/category";

import { convertImages, fromArrayListKey, inArrayValueByKey, toArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ProductsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function ProductsCategorys(props) {
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" }, 
    { sort: true, title: "Изображение" },
    { sort: true, title: "Название" },
    { sort: false, title: "Корневая категория " },
    { sort: true, title: "Сортировка" },
    { sort: true, title: "Показать на главной" },
    { sort: true, title: "Показать в меню" },
  
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getCategoryList();
  }, []);

  useEffect(() => {
    let arr = []; 
    props.category.list.sort((a,b) => a.sort - b.sort ).map((item) => {
      item.images = item.image ? Env.PUBLIC_URL + item.image : null;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("editCategory", item);
            navigate("/category/edit");
          },
        },
       
        { image: item.images },
        { text: item.title },
        {
          statusInfo: inArrayValueByKey(props.category.list, "id", item.root_category_id) ? inArrayValueByKey(props.category.list, "id", item.root_category_id).title  : " -- -- ", 
          statusList: fromArrayListKey(props.category.list, "title"),
          onStatusChange: (val) => {
       
            let _item = item;
            _item.root_category_id = props.category.list[val].id;
            props.update(_item);
          },
        
        },
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            _item.sort = v ? item.sort + 1 : item.sort - 1 ;
            props.update(_item).then(() =>  props.getCategoryList(false) );
          },
        },
        {
          button: item.is_site_menu ? "eye-open" : "eye",
          color: item.is_site_menu ? "teal" : "red", 
          onClick: () => {
            let _item = item;
            _item.is_site_menu = _item.is_site_menu == 1 ? 0 : 1;
            props.update(_item);
          },
        },
        {
          button: item.is_menu ? "eye-open" : "eye", 
          color: item.is_menu ? "teal" : "red", 
          onClick: () => {
            let _item = item;
            _item.is_menu = _item.is_menu == 1 ? 0 : 1;
            props.update(_item);
          }
        },

       
        {
          button:  "delete",
          color: "black",
          onClick: () => {
            let _item = item;
            _item.is_del = 1;
            props.update(_item);
          },
        },
      ]);
    });
    setTableListOrders( []);
    setTimeout(() => setTableListOrders( arr ) , 100 );
  }, [props.category.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список категорий" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/add/category");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список категорий"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
      {/* modal deal add */}
      <UiModalProductAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          props.addOrder(data).then(() => {
            setModalDealAddActive(false);
          });
        }}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    getCategoryList: getCategoryListAction,
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    update: updateAction,
  }
)(ProductsCategorys);
