import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import UiInputText from "../../components/inputs/UiInputText";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiCardAddPhoto from "../../components/cards/UiCardAddPhoto";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import {
    addCategory as addCategoryAction,
    update as updateCategoryAction,
} from "../../redux/modules/category";
import {
    addStorage as addStorageAction,
    update as updateAction,
} from "../../redux/modules/storage";

import {
    toArrayListKey, toArrayImagesListKey, fromArrayListKey,
    inArrayValueByKey, inArray, inArrayValue,
    unCheckInArray
} from "../../utilities/array";

import Env from "../../services/Env";
import { addFileBase64 } from "../../services/Storage";
import getCroppedImg from "../../services/CropImage";
import StorageConstant from "../../constants/StorageConstant";
import OrderConstant from "../../constants/OrderConstant";


import "./ProductsStyle.scss";

function ProductsCategoryEditPage(props) {
    const navigate = useNavigate();
    const [order, setOrder] = useState({
        isEdit: false,
        title: "",
        category: 0,
        is_menu: 1,
        is_site_menu: 0,
        description: "",
        price: 0,
        sort: 0,
        image: null
    });
    const [state, setState] = useState({
        isLoad: false,
        isDialogCrop: false,
        savedImages: [],
        removeArray: [],
        imagesList: []
    });
    const [additional, setAdditional] = useState([])

    useEffect(() => {
        let editData = retrieveData("editCategory");
        if (editData) {
            console.log(editData)
            let _imgArr = editData.images ? editData.images.split(",") : [];
            let imgs = toArrayImagesListKey(_imgArr);
            setState({ ...state, imagesList: imgs })
            editData.images = imgs;
            editData.isEdit = true;
            setOrder(editData);

        }
    }, []);

    /**
     *
     * @param {string} key
     * @param {any} val
     */
    function updateOrderObj(key, val) {
        let _obj = order;
        _obj[key] = val;
        setOrder({ ..._obj });
    }

    /**
     * 
     * @param {string} file 
     * @param {any} cb 
     */

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    /**
     * 
     * @param {*} _croppedAreaPixels 
     */

    function _onSaveImage(_croppedAreaPixels) {
        setState({ ...state, isDialogCrop: false });
        getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
            setState({ ...state, selectedFileName: res, });
            let arr = state.savedImages;
            arr.splice(0, 1);
            setState({ ...state, savedImages: arr });
            upload(res);

        });
    }

    function upload(file) {
        setState({
            ...state, imageLoad: true
        });
        addFileBase64(file, 0).then((res) => {
            setState({
                ...state, imageLoad: false
            });
            if (res.success) {
                let arr = state.imagesList;
                arr.push({ id: (arr.length + 1).toString(), content: res.response });
                setState({
                    ...state,
                    imagesList: (arr)
                })

                console.log(state.savedImages, arr);

                updateOrderObj("image", res.response)

                setState({
                    ...state, isDialogCrop: state.savedImages.length > 0
                });
            } else {
                console.log(res);
            }
        });
    }


    return (
        <div id="page" className="product-add-page">
            <div className="product-add-wrap">
                <div className="product-add-about add">
                    <div className="product-add-about-title">
                        <div className="product-add-about-title-wrap">
                            <h3>Редактировать категорию</h3>
                        </div>
                    </div>
                    <div className="product-add-notes">
                        <div className="product-add-notes-list-wrap">
                            <div className="product-add-info">
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Название:"
                                        placeholder="Название"
                                        value={order.title}
                                        onChange={(val) => {
                                            updateOrderObj("title", val);
                                        }}
                                    />
                                </div>
                                <div className="product-add-info-form">
                                    <UiDropDown
                                        label="Тип:"
                                        items={[
                                            { value: 0, text: "Категория" },
                                            { value: 1, text: "Под категория" },
                                        ]}
                                        onChange={(val) => updateOrderObj("category", val)}
                                    />
                                </div>
                                <div className="product-add-info-form">
                                    <UiDropDown
                                        label="Показать в меню:"
                                        value={order.is_site_menu}
                                        items={[
                                            { value: 1, text: "Скрыть" },
                                            { value: 0, text: "Показать" },
                                        ]}
                                        onChange={(val) => updateOrderObj("is_menu", val)}
                                    />
                                </div>
                                <div className="product-add-info-form">
                                    <UiDropDown
                                        label="Показать на главной:"
                                        value={order.is_menu}
                                        items={[
                                            { value: 1, text: "Скрыть" },
                                            { value: 0, text: "Показать" },
                                        ]}
                                        onChange={(val) => updateOrderObj("is_site_menu", val)}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Сортировка"
                                        value={order.sort}
                                        onChange={(val) => updateOrderObj("sort", val)}
                                    />
                                </div>


                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Цена"
                                        value={order.price}
                                        onChange={(val) => updateOrderObj("price", val)}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Описание"
                                        value={order.description}
                                        onChange={(val) => updateOrderObj("description", val)}
                                    />
                                </div>


                                {order.image &&
                                    <div className="product-add-info-form">
                                        <UiCardAddPhoto
                                            image={Env.PUBLIC_URL + order.image}
                                            remove={() => {
                                                updateOrderObj("image", null)
                                            }}
                                        />
                                    </div>
                                }

                                <div className="product-add-info-form product-add-info-photo">
                                    <UiCardAddPhoto
                                        upload={(e) => {
                                            if (e.target.files.length > 0) {
                                                for (let i = 0; i < e.target.files.length; i++) {
                                                    let arr = state.savedImages;
                                                    getBase64(e.target.files[i], (result) => {
                                                        arr.push(result);
                                                        setState({
                                                            ...state,
                                                            savedImages: arr,
                                                            base64Image: result,
                                                            isDialogCrop: true,
                                                        });
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </div>



                                <div className="product-add-info-form product-add-info-photo">
                                    <UiButtonColor
                                        color="primary"
                                        text={"Редактировать"}
                                        small={true}
                                        onClick={() => {
                                            if (order.title != "") {
                                                let _order = order;
                                                props.update(_order).then((res) => {
                                                    if (res) navigate(`/category/all`);
                                                });
                                            } else {
                                                alert("Заполните поля название  ");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <UiModalImageCrop
                aspect={2 / 1}
                modalOpen={state.isDialogCrop}
                base64Image={state.savedImages[0]}
                onSave={(val) => _onSaveImage(val)}
                cancelClick={() => setState({ ...state, isDialogCrop: false })}
            />


        </div>
    );
}

export default connect(({ category }) => ({ category }), {
    addCategory: addCategoryAction,
    update: updateCategoryAction
})(ProductsCategoryEditPage);
